$black: #242424;
$warm-grey: #8b8b8b;
$coral: #fb654a;

body {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: normal;
    line-height: 1.8;
    color: $black;

    @include media-breakpoint-down(xs) {
        font-size: 15px;
    }
}

a {
    transition: .3s;
    color: $black;
    opacity: 1;

    &:hover {
        opacity: .8;
        color: $black;
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }
}

.fw-n {
    font-weight: normal;
}

.semi {
    font-weight: 600;
}

.fz {
    &_13 {
        font-size: 13px;
    }

    &_10 {
        font-size: 10px;
    }

    &_20 {
        font-size: 20px;
    }
}

.pad-80 {
    padding-top: 80px;

    @include media-breakpoint-down(lg) {
        padding-top: 60px;
    }

    @include media-breakpoint-down(md) {
        padding-top: 50px;
    }
}

.pad-50 {
    padding-top: 50px;

    @include media-breakpoint-down(lg) {
        padding-top: 45px;
    }

    @include media-breakpoint-down(md) {
        padding-top: 40px;
    }
}

button {
    outline: none;
    border: none;
    background-color: transparent;

    &:focus {
        outline: none;
    }
}

input,
textarea {
    outline: none;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.textarea {
    height: 100px !important;
    padding-top: 15px;
}

%bg {
    background: center no-repeat;
    background-size: cover;
}

%border {
    border: solid 2px #f5f5f5;
}

.nowrap {
    flex-wrap: nowrap;
}

.f-wrap {
    flex-wrap: wrap;
}

.header {
    background-color: #fff;
    z-index: 99;

    @include media-breakpoint-down(md) {
        position: fixed;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        width: 100%;
        top: 0;
    }

    &__left {
        height: 65px;

        @include media-breakpoint-down(sm) {
            height: 45px;
        }

        @include media-breakpoint-down(xs) {
            height: 40px;
        }
    }

    &__right {
        height: 65px;

        @include media-breakpoint-down(sm) {
            height: 45px;
        }

        @include media-breakpoint-down(xs) {
            height: 40px;
        }
    }

    &__grafic {
        font-size: 12px;
        font-weight: 300;
        line-height: normal;
        width: 90px;
    }

    &__bottom {
        height: 60px;
        width: 98%;
        z-index: 9;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }

    &__sort-down {
        width: 20px;
        height: 20px;
        display: inline-flex;
        margin-left: 5px;
        margin-bottom: 5px;
        color: $black;
        flex-shrink: 0;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
        }

        @include media-breakpoint-down(xs) {
            width: 15px;
            height: 15px;
        }
    }

    &__catalog-link {
        font-size: 20px;

        @include media-breakpoint-down(xs) {
            font-size: 16px;
        }
    }

    &__whats {
        width: 25px;
    }
}

.nav {
    &__top {
        height: 65px;

        @include media-breakpoint-down(md) {
            height: auto;
        }
    }

    &_bottom {
        background-image: linear-gradient(to top, #2d2d2d, #525453);
    }
}

.burger-wrap {
    height: 65px;

    @include media-breakpoint-down(sm) {
        height: 45px;
    }

    @include media-breakpoint-down(xs) {
        height: 40px;
    }
}


.logo {
    height: 50px;
    width: 50px;

    @include media-breakpoint-down(sm) {
        height: 35px;
        width: 35px;
    }

    @include media-breakpoint-down(xs) {
        height: 30px;
        width: 30px;
    }
}

.logo-text {
    font-size: 17px;
    font-weight: 600;
    line-height: 0.9;
    color: #303030;
    margin-right: 18px;

    &_white {
        color: #fff;
    }
}

.adress {
    &__marker {
        margin-right: 10px;
    }

    &__text {
        font-size: 12px;
        font-weight: 300;
        line-height: 1.11;
    }
}

.catalog-submenu {
    display: none;
    list-style: none;
}
.open-submenu {
    padding: 5px 10px 14px;
    width: 32px;
    height: 35px;
}

.nav-wrap {
    &__main-nav {
        display: none;

        @include media-breakpoint-up(lg) {
            display: inline-flex !important;
        }
    }

    &__catalog {
        display: none;

        @include media-breakpoint-up(lg) {
            display: none !important;
        }
    }
}



.nav-link {
    &_top {
        font-size: 12px;
        line-height: normal;
        color: $black;

        &:hover {
            color: $coral;
        }

        @include media-breakpoint-down(md) {
            text-transform: uppercase;
            padding-left: 0;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    &_bottom {
        font-size: 15px;
        color: #fff;
        transition: .3s;
        padding: 1rem 1.2rem;

        &:hover {
            color: $coral;
        }
    }
}


.dark-submenu {
    position: absolute;
    background-color: #2d2d2d;
    color: #ffffff;
    z-index: 9;
    list-style: none;
    padding-left: 0;
    width: 200px;
    opacity: 0;
    transition: .3s;
    display: none;
    padding-bottom: .5rem;

    .nav-link_bottom {
        padding-top: .7rem;
        padding-bottom: 0.7rem;
    }
}

.nav-item {
    position: relative;

    &_bottom {

        &:hover {
            .dark-submenu {
                opacity: 1;
                display: block;
            }
        }
    }
}

.nav-item_bottom {
    text-transform: uppercase;
}

.nav-item_bottom.active > .nav-link_bottom,
.nav-item_top.active > .nav-link_top {
    color: $coral;
    opacity: .8;
}

.main-button {
    height: 37px;
    background-image: linear-gradient(to top, #fb7249, #fb8942);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding-left: 13px;
    padding-right: 13px;
    transition: .3s;
    position: relative;
    z-index: 2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: #fff;
        opacity: 1;
        background-image: linear-gradient(to top, #2d2d2d, #525453);
    }

    &_fz_12 {
        font-size: 12px;

        @include media-breakpoint-down(sm) {
            font-size: 11px;
            height: 32px;
        }
    }

    &_mt_55 {
        margin-top: 55px;
    }

    &_w {
        &_180 {
            width: 180px;
        }
    }

    &_h {
        &_50 {
            height: 50px;
        }
    }
}

.phone {
    width: 170px;
    align-content: center;
    margin-right: 25px;

    @include media-breakpoint-down(lg) {
        width: 135px;
    }

    @include media-breakpoint-down(md) {
        margin-right: 15px;
    }

    @include media-breakpoint-down(sm) {
        margin-right: 0;
        width: auto;
    }

    &__link {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
        color: $black;

        @include media-breakpoint-down(lg) {
            font-size: 17px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 16px;
        }
    }

    &__text {
        font-size: 12px;
        font-weight: 300;
        line-height: 1.2;
        color: #25d366;

        &:hover {
            color: #128c7e;
        }
    }
}

.mobile-logo {
    margin-top: 60px;
}

.offer {


    @include media-breakpoint-down(md) {
        margin-top: 65px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
    }

    &__red-block {
        height: 100px;
        width: 280px;
        background: red;
        margin-left: -30px;
        margin-top: 30px;
        font-size: 20px;
        padding-left: 30px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
            width: 220px;     
        }
    }



    &__title {

        margin-top: 30px;

        @include media-breakpoint-down(sm) {
            font-size: 26px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 24px;
        }
    }
}

.offer-banner {
    margin-top: 25px;
    



    &__inner {
        text-transform: uppercase;
        padding-left: 30px;
        height: 465px;
        color: #fff;
        background: #000000 no-repeat center;
        background-size: cover;
        background-image: linear-gradient( to right, rgba(0,0,0, 1) 0%, rgba(0,0,0, .7) 50%, transparent 100%), url(../img/offer/banner.jpg);
    }
    
}

// .offer-slider {
//     position: relative;

//     .slick-dots {
//         bottom: 17px;
//     }


//     &__slide {
//         height: 465px;
//         background: $black no-repeat center;
//         background-size: cover;

//         @include media-breakpoint-down(md) {
//             height: 400px;
//         }
//     }
// }

.orange-item {
    align-content: flex-start;
    margin-top: 25px;
    height: 146px;
    color: #fff;

    @include media-breakpoint-down(md) {
        height: 135px;
    }

    @include media-breakpoint-down(sm) {
        height: 90px;
    }


    &:hover {
        opacity: 1;
        color: #fff;
    }

    &_thirst {
        background-image: linear-gradient(to top, rgba(251, 114, 73, .9), rgba(251, 137, 66, .9) 100%), url(../img/orange/1.jpg);
        margin-top: 25px;

        &:hover {
            background-image: linear-gradient(to top, rgba(45, 45, 45, .9), rgba(82, 84, 83, .9) 100%), url(../img/orange/1.jpg);
        }

        @include media-breakpoint-down(lg) {
            margin-top: 14px;
        }

        @include media-breakpoint-down(sm) {
            height: 80px;
        }

        @include media-breakpoint-down(xs) {
            height: 75px;
        }
    }

    &_second {
        background-image: linear-gradient(to top, rgba(251, 114, 73, .9), rgba(251, 137, 66, .9) 100%), url(../img/orange/2.jpg);
        margin-top: 14px;

        &:hover {
            background-image: linear-gradient(to top, rgba(45, 45, 45, .9), rgba(82, 84, 83, .9) 100%), url(../img/orange/2.jpg);
        }

        @include media-breakpoint-down(sm) {
            height: 80px;
        }

        @include media-breakpoint-down(xs) {
            height: 75px;
        }
    }

    &_third {
        background-image: linear-gradient(to top, rgba(251, 114, 73, .9), rgba(251, 137, 66, .9) 100%), url(../img/orange/3.jpg);
        margin-top: 14px;

        &:hover {
            background-image: linear-gradient(to top, rgba(45, 45, 45, .9), rgba(82, 84, 83, .9) 100%), url(../img/orange/3.jpg);
        }
    }


    &__up-text {
        padding-top: 5px;
        padding-right: 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.55;
        color: #fff;
    }

    &__text {
        font-size: 22px;
        font-weight: 600;
        line-height: 1.45;
        max-width: 225px;
        flex-shrink: 0;

        @include media-breakpoint-down(xl) {
            font-size: 19px;
            max-width: 205px;
        }

        @include media-breakpoint-down(md) {
            font-size: 16px;
            max-width: 180px;
        }
    }
}

h2,
.main-title {
    font-size: 30px;
    line-height: 1.3;
    margin-bottom: 30px;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
        font-size: 27px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 23px;
    }

    &.mw-240 {
        @include media-breakpoint-down(xs) {
            max-width: 240px;
        }
    }
}

h3 {
    font-size: 26px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 25px;

    @include media-breakpoint-down(sm) {
        font-size: 23px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 20px;
    }
}

h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
        font-size: 20px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 18px;
    }
}

.title-link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.title-ico {
    margin-bottom: 26px;
    width: 20px;
    height: 20px;

    path {
        fill: $coral;
    }
}

.main-item {
    margin-bottom: 25px;


    &__inner {
        display: block;
        padding-top: 25px;
        padding-left: 30px;
        padding-right: 15px;
        height: 290px;
        max-width: 425px;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(lg) {
            height: 220px;
            padding-top: 20px;
            padding-left: 20px;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 15px;
            height: 200px;
        }

        @include media-breakpoint-down(xs) {
            max-width: 345px;
            height: 240px;
        }
    }

    &__title {
        font-size: 22px;
        line-height: 1.55;
        color: #ffffff;

        @include media-breakpoint-down(lg) {
            font-size: 20px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 17px;
        }
    }
}

.orange-block {

    &__title {
        font-size: 28px;
        font-weight: 600;
        line-height: 1.55;
        color: #ffffff;

        @include media-breakpoint-down(xl) {
            font-size: 25px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 21px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 17px;
        }

        &_fz {
            &_20 {
                font-weight: 400;
                font-size: 20px;

                @include media-breakpoint-down(xs) {
                    font-size: 16px;
                }
            }
        }
    }

    &__text {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.37;
        color: #ffffff;

        @include media-breakpoint-down(xs) {
            font-size: 15px;
        }

        &_fz {
            &_16 {
                font-size: 16px;

                @include media-breakpoint-down(xs) {
                    font-size: 15px;
                }
            }
        }
    }

    &__row {
        height: 150px;
        background: url(../img/elems/orange.jpg) no-repeat center #fb7249;
        background-size: cover;
        padding: 45px 100px;


        @include media-breakpoint-down(xl) {
            padding: 45px 35px;
        }

        @include media-breakpoint-down(lg) {
            height: auto;
        }

        @include media-breakpoint-down(md) {
            padding: 45px 10px;
        }

        @include media-breakpoint-down(sm) {
            padding-top: 25px;
            padding-bottom: 25px;
        }

        @include media-breakpoint-down(xs) {
            padding-left: 0;
            padding-right: 0;
        }

        &_h {
            &_auto {
                height: auto;
            }
        }
    }
}

.call-form {
    position: relative;

    &__input {
        border: none;
        height: 50px;
        padding-left: 20px;

        &_border {
            border: 2px solid #F5F5F5 !important;
        }
    }
}

.button-dark {
    display: flex;
    height: 50px;
    background-image: linear-gradient(to top, #2d2d2d, #525453);
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    transition: .3s;

    &:hover {
        opacity: .9;
        color: #ffffff;
    }
}

::-webkit-input-placeholder {
    color: #959595;
    opacity: 1;
}

/* webkit */
::-moz-placeholder {
    color: #959595;
    opacity: 1;
}

/* Firefox 19+ */
:-moz-placeholder {
    color: #959595;
    opacity: 1;
}

/* Firefox 18- */
:-ms-input-placeholder {
    color: #959595;
    opacity: 1;
}

/* IE */

.politic {
    font-size: 10px;
    font-weight: 300;
    color: #ffffff;
    position: absolute;
    bottom: -23px;
    right: 15px;
    margin-bottom: 0;

    @include media-breakpoint-down(lg) {
        left: 15px;
    }

    @include media-breakpoint-down(md) {
        position: relative;
        bottom: -8px;
        left: auto;
        right: auto;
    }

    @include media-breakpoint-down(xs) {
        padding-left: 15px;
        padding-right: 15px;
    }

    a {
        text-decoration: underline;
    }
}

.kitchens {
    @include media-breakpoint-down(xs) {
        padding-bottom: 20px !important;
    }
}

.product {
    &__head {
        height: 319px;
        @extend %bg;

        @include media-breakpoint-down(xl) {
            height: 250px;
        }

        @include media-breakpoint-down(lg) {
            height: 210px;
        }

        @include media-breakpoint-down(md) {
            height: 225px;
        }

        @include media-breakpoint-down(sm) {
            height: 170px;
        }

        @include media-breakpoint-down(xs) {
            height: 250px;
        }

        &_sm {
            height: 240px;

            @include media-breakpoint-down(xl) {
                height: 210px;
            }

            @include media-breakpoint-down(sm) {
                height: 170px;
            }

            @include media-breakpoint-down(xs) {
                height: 250px;
            }
        }
    }

    &__inner {
        @extend %border;
        transition: .3s;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(xs) {
            max-width: 345px;
        }

        &:hover {
            box-shadow: 4px 10px 20px rgba(0, 0, 0, 0.07);
            border-color: transparent;
            opacity: 1;

            .product__head {
                transition: .3s;
                opacity: .9;
            }
        }
    }

    &__body {
        padding-top: 12px;
    }

    &__footer {
        padding-bottom: 25px;
    }

    &__category-name {
        font-size: 12px;
        line-height: 1.55;
        color: #7a7a7a;
        margin-bottom: 5px;
    }

    &__name {
        font-size: 22px;
        line-height: 1.32;
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
            font-size: 17px;
        }
    }

    &__price {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.55;
        color: $coral;

        &_small {
            @include media-breakpoint-down(xs) {
                font-size: 17px;
            }
        }
    }

    &__price-valute {
        font-size: 16px;
    }

    &__old-price {
        font-size: 15px;
        line-height: 1.55;
        color: $warm-grey;
        text-decoration: line-through;
    }

    &__old-price-valute {
        font-size: 12px;
    }

    &__options {
        font-size: 20px;
        font-weight: 300;

        @include media-breakpoint-down(xs) {
            font-size: 16px;
        }
    }

    &__buttons {
        max-width: 425px;
    }

    &_slider {
        .product__inner {
            &:hover {
                box-shadow: none;
                @extend %border;
            }
        }
    }
}

.products-slider__arrows,
.products-slider__arrows-boxes {
    position: relative;
}

.arrow {
    position: absolute;
    top: -67px;
    cursor: pointer;

    &_prev {
        right: 65px;
    }

    &_next {
        right: 15px;
    }

    &__box {
        transition: .3s;
        fill: #fff;
    }

    &__arrow {
        display: inline-block;
        transition: .3s;
        fill: #272727;



    }

    &:hover {
        .arrow__box {
            fill: #272727;
        }

        .arrow__arrow {
            fill: #fff;
        }
    }
}

.review {
    line-height: 1.55;

    &__inner {
        @include media-breakpoint-down(sm) {
            max-width: 345px
        }
    }

    &__up {
        @extend %border;
        height: 315px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;

        @include media-breakpoint-down(xl) {
            height: 216px;
        }

        @include media-breakpoint-down(lg) {
            padding-left: 20px;
            padding-right: 15px;
        }

        @include media-breakpoint-down(md) {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @include media-breakpoint-down(sm) {
            height: auto;
        }
    }

    &__name {
        font-size: 22px;

        @include media-breakpoint-down(md) {
            font-size: 18px;
        }
    }

    &__text {
        font-size: 15px;
        font-weight: 300;

        @include media-breakpoint-down(lg) {
            font-size: 14px;
        }

        @include media-breakpoint-down(md) {
            font-size: 13px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }

        P {
            @include media-breakpoint-down(md) {
                margin-bottom: 5px;
            }
        }
    }

    &__link {
        font-size: 15px;
        color: #6d6d6d;

        @include media-breakpoint-down(md) {
            font-size: 13px;
        }
    }

    &__bottom {
        height: 216px;
        @extend %bg;
    }
}

.event {

    &__inner {
        margin: {
            left: auto;
            right: auto;
        }

        @include media-breakpoint-down(xs) {
            max-width: 345px;
        }
    }

    &__head {
        height: 210px;
        @extend %bg;

        @include media-breakpoint-down(sm) {
            height: 175px;
        }

        @include media-breakpoint-down(xs) {
            height: 210px;
        }
    }

    &__body {
        @extend %border;

        padding: {
            top: 22px;
            bottom: 22px;
        }
    }

    &__name {
        font-size: 18px;
        line-height: 1.24;
        min-height: 43px;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
            min-height: 35px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 16px;
        }
    }

    &__text {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.28;
        height: 70px;
    }

    &__link {
        font-size: 14px;
        line-height: 1.28;
        color: #6c6c6c;

        @include media-breakpoint-down(xs) {
            font-size: 13px;
        }
    }
}

.about-block {
    &__text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.9;
    }

    &__img {
        height: 349px;
        max-width: 425px;
        @extend %bg;

        @include media-breakpoint-down(xs) {
            height: 280px;
        }
    }
}

.work-item {
    &__inner {
        position: relative;
        @extend %border;
        padding-top: 7px;
        padding-bottom: 20px;
        height: 100%;
    }

    &__free {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.55;
        color: $coral;
    }

    &__text {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.28;
        color: #000000;

        @include media-breakpoint-down(xl) {
            font-size: 14px;
        }
    }

    &__title {
        font-size: 18px;
        line-height: 1.28;
        color: #000000;

        @include media-breakpoint-down(xl) {
            font-size: 16px;
        }

        margin: {
            top: 5px;
            bottom: 20px;
        }
    }

    &__number {
        position: absolute;
        right: 6px;
        bottom: 10px;
        opacity: 0.1;
        background-image: linear-gradient(to top, #fb7249, #fb8942);
        font-size: 100px;
        font-weight: 600;
        line-height: 1;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__content {
        padding-right: 43px;
    }
}

.footer {
    color: #fff;
    font-size: 12px;
    font-weight: 300;

    &__row {
        padding-top: 1rem;
        position: relative;
        background-image: linear-gradient(90deg, #2D2D2D 0%, #525453 100%);

        @include media-breakpoint-down(md) {
            padding-top: 50px;
        }
    }

    a {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }

    &__nav {
        list-style: none;
        padding-left: 0;

    }

    &__li {
        margin-top: 5px;
    }

    &__politic {
        text-decoration: underline;
    }
}

.toup {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 33px;
    height: 33px;
    z-index: 90;
}

.about {

    &__inner-big,
    &__inner-small {
        @extend %bg;
    }

    &__inner-big {
        height: 405px;

        @include media-breakpoint-down(lg) {
            height: 350px;
        }

        @include media-breakpoint-down(md) {
            height: 250px;
        }

        @include media-breakpoint-down(sm) {
            max-width: 425px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__inner-small {
        height: 250px;

        @include media-breakpoint-down(sm) {
            max-width: 425px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.work {
    @include media-breakpoint-down(lg) {
        max-width: 668px;
    }

    @include media-breakpoint-down(xs) {
        max-width: 465px;
    }

    &__slider {
        .slick-next, .slick-prev {
            width: 50px;
            height: 50px;
            z-index: 10;
        }

        .slick-next:before, .slick-prev:before  {
            font-size: 50px;
            color: #e2561a;
        }

        .slick-prev {
            left: -10px;
        }

        .slick-next {
            right: -10px;
        }
    }


    &__slide {
        height: 405px;
        @extend %bg;

        @include media-breakpoint-down(sm) {
            height: 325px;
        }

        @include media-breakpoint-down(xs) {
            height: 230px;
        }
    }

    &__text {
        @extend %border;

        padding: {
            top: 22px;
            bottom: 25px;
            left: 55px;
            right: 85px;
        }

        @include media-breakpoint-down(xl) {
            padding-left: 35px;
            padding-right: 35px;
        }

        @include media-breakpoint-down(xs) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__desc {
        font-weight: 300;
    }
}

.page-link {
    border: none;
    color: $black;
    transition: .3s;

    &:hover {
        opacity: .7;
        color: $black;
        background-color: transparent;
    }
}

.page-item.active .page-link {
    z-index: 1;
    border: 1px solid $black;
    color: $black;
    background-color: transparent;
}

.news-item {
    height: 100%;
    align-content: flex-start;
    @extend %border;

    @include media-breakpoint-down(sm) {
        max-width: 328px;
    }

    h4 {
        @include media-breakpoint-down(lg) {
            margin-bottom: 5px;
        }

        @include media-breakpoint-down(lg) {
            font-size: 20px;
        }
    }

    &__img {
        height: 226px;
        @extend %bg;
    }

    &__text {
        padding-top: 5px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;

        @include media-breakpoint-down(lg) {
            padding-top: 20px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__desc {
        font-weight: 300;
        height: 115px;
        max-width: 905px;

        @include media-breakpoint-down(lg) {
            height: auto;
        }
    }

    &__date {
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 14px;
        color: #5C5C5C;

        @include media-breakpoint-down(lg) {
            margin-bottom: 10px;
        }
    }

    &__link {
        font-weight: 300;
        text-decoration: underline;
    }
}

.page {
    @include media-breakpoint-down(md) {
        padding-top: 65px;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 45px;
    }

    @include media-breakpoint-down(xs) {
        padding-top: 40px;
    }
}

.contacts {
    font-weight: 300;

    &__way {
        margin-top: 80px;
        margin-bottom: 80px;

        @include media-breakpoint-down(md) {
            margin-top: 45px;
            margin-bottom: 60px;
        }
    }

    &__way-desc {
        max-width: 600px;
    }

    &__photo {
        height: 350px;
        @extend %bg;
        background-image: url(/img/shop.jpg);

        @include media-breakpoint-down(lg) {
            height: 300px;
        }

        @include media-breakpoint-down(xs) {
            height: 190px;
        }
    }
}

.contact {
    border-bottom: 1px solid #AAAAAA;
    height: 60px;

    &__tel {
        font-weight: 600;
        font-size: 20px;
    }

    &__left {
        font-size: 20px;

        @include media-breakpoint-down(xs) {
            font-size: 16px;
        }
    }
}

.map-container {
    height: 360px;
}

.map {
    width: 100%;
    height: 100%;
}

.filters {
    @extend %border;

    padding: {
        top: 10px;
        left: 30px;
        right: 15px;
        bottom: 10px;
    }

    &__title {
        font-weight: 600;
        font-size: 20px;

        @include media-breakpoint-down(xl) {
            font-size: 18px;
        }
    }

    &__content {
        padding-top: 20px;

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: block !important;
        }

    }

    &__ul {
        padding-left: 0;
    }

    &__li {
        list-style: none;
        padding-left: none;

        a:hover {
            color: #fb654a;
            opacity: 1;
        }
    }
}

.comment {
    @extend %border;

    @include media-breakpoint-down(lg) {
        max-width: 425px;
    }

    &__img {
        height: 265px;
        @extend %bg;
    }

    &__text {
        padding-top: 5px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;

        @include media-breakpoint-down(lg) {
            padding-top: 20px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__desc {
        max-width: 790px;
    }

    &__date {
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 14px;
        color: #5C5C5C;

        @include media-breakpoint-down(lg) {
            margin-bottom: 10px;
        }
    }

    h4 {
        @include media-breakpoint-down(lg) {
            margin-bottom: 5px;
        }

        @include media-breakpoint-down(lg) {
            font-size: 20px;
        }
    }
}

.steps {
    margin-bottom: 100px;
}

.step {
    &__desc {
        color: #8B8B8B;
        max-width: 685px;
    }

    &__area-kind {
        font-weight: 600;
        text-align: center;

        &_sm {
            font-size: 15px;
            text-align: left;
            height: 45px;
            line-height: 1.4;

            @include media-breakpoint-down(xs) {
                font-size: 14px;
            }
        }
    }

    &__price {
        font-size: 13px;
    }

    .label {
        border: 2px solid #f5f5f5;
        padding: 20px 10px;
        padding-bottom: 20px;
        transition: .3s;
        cursor: pointer;
    }

    &__img {
        max-width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 120px;

        @include media-breakpoint-down(xs) {
            height: 100px;
        }

        &_h {
            &_250 {
                height: 250px;

                @include media-breakpoint-down(md) {
                    height: 230px;
                }

                @include media-breakpoint-down(sm) {
                    height: 160px;
                }

                @include media-breakpoint-down(xs) {
                    height: 200px;
                }
            }
        }
    }

    .politic {
        right: auto;
        bottom: auto;

        @include media-breakpoint-down(xs) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__input {
        display: none;
    }
}

.step__input:checked+label {
    border-color: $coral;
}

.step {
    &__price-result {
        color: $coral;
    }
}

.next-step-wrap {
    display: none;
}

.step:not(:first-child) {
    display: none;
}

.metering-call {
    cursor: pointer;
}


.slick-slide:focus {
    outline: none;
}


.slick-dots li button:before {
    display: inline-flex;
    content:'';
    width: 10px;
    height: 10px;
    background: #959595;
    opacity: 1;
}

.slick-list {
    padding-bottom: 0;
}

.slick-dots li.slick-active button:before {
    background: #e2561a;
}

.flickity-page-dots .dot {
    border-radius: 0;
    opacity: 1;
    background: #c4c4c4;
}

.flickity-page-dots .dot.is-selected {
    background: #e2561a;
}

.flickity-page-dots {
    bottom: 15px;
}

.breadcrumb {
    border-bottom: 2px solid #F5F5F5;
    background-color: #fff;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.55;
    margin-bottom: 33px;
    padding-left: 1px;
    padding-right: 0;
} 

.work__slider {
    .slick-dots {
        bottom: 10px;
    }

    .slick-dots li button:before {
        background: #fff;
        opacity: 1;
    }

    .slick-dots li.slick-active button:before {
        background: #e2561a;
    }
}

.pagination {
    .page-link:focus {
        box-shadow: none;
    }
    
    .page-item:first-child .page-link {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .page-item:last-child .page-link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.fotorama__thumb-border {
    border-color: #fb654a!important;
}


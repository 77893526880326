@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Light.eot');
	src: local('Open Sans Light'), local('OpenSans-Light'),
		url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-Light.woff') format('woff'),
		url('../fonts/OpenSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: 'fallback';
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Regular.eot');
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
		url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-Regular.woff') format('woff'),
		url('../fonts/OpenSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: 'fallback';
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-SemiBold.eot');
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
		url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-SemiBold.woff') format('woff'),
		url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: 'fallback';
}

